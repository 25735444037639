import XyroBubbleMask from './xyro-bubble-mask.png'

const XyroBubble: React.FC<{ className?: string }> = ({ className }) => (
  <svg
    className={className}
    xmlns='http://www.w3.org/2000/svg'
    width='260'
    height='260'
    viewBox='0 0 260 260'
    fill='none'
  >
    <g filter='url(#filter0_b_115_2677)'>
      <mask
        id='path-1-inside-1_115_2677'
        fill='white'
      >
        <path d='M220.149 132.47C220.149 181.981 180.012 222.118 130.501 222.118C80.9895 222.118 40.8526 181.981 40.8526 132.47C40.8526 82.9587 80.9895 42.8218 130.501 42.8218C180.012 42.8218 220.149 82.9587 220.149 132.47Z' />
      </mask>
      <path
        d='M220.149 132.47C220.149 181.981 180.012 222.118 130.501 222.118C80.9895 222.118 40.8526 181.981 40.8526 132.47C40.8526 82.9587 80.9895 42.8218 130.501 42.8218C180.012 42.8218 220.149 82.9587 220.149 132.47Z'
        fill='url(#paint0_radial_115_2677)'
      />
      <path
        d='M217.752 132.47C217.752 180.658 178.689 219.721 130.501 219.721V224.515C181.336 224.515 222.546 183.305 222.546 132.47H217.752ZM130.501 219.721C82.3132 219.721 43.2494 180.658 43.2494 132.47H38.4558C38.4558 183.305 79.6658 224.515 130.501 224.515V219.721ZM43.2494 132.47C43.2494 84.2824 82.3132 45.2186 130.501 45.2186V40.425C79.6658 40.425 38.4558 81.635 38.4558 132.47H43.2494ZM130.501 45.2186C178.689 45.2186 217.752 84.2824 217.752 132.47H222.546C222.546 81.635 181.336 40.425 130.501 40.425V45.2186Z'
        fill='url(#paint1_linear_115_2677)'
        mask='url(#path-1-inside-1_115_2677)'
      />
    </g>
    <g filter='url(#filter1_b_115_2677)'>
      <mask
        id='path-3-inside-2_115_2677'
        fill='white'
      >
        <path d='M220.149 132.47C220.149 181.981 180.012 222.118 130.501 222.118C80.9895 222.118 40.8526 181.981 40.8526 132.47C40.8526 82.9587 80.9895 42.8218 130.501 42.8218C180.012 42.8218 220.149 82.9587 220.149 132.47Z' />
      </mask>
      <path
        d='M220.149 132.47C220.149 181.981 180.012 222.118 130.501 222.118C80.9895 222.118 40.8526 181.981 40.8526 132.47C40.8526 82.9587 80.9895 42.8218 130.501 42.8218C180.012 42.8218 220.149 82.9587 220.149 132.47Z'
        fill='url(#paint2_radial_115_2677)'
      />
      <path
        d='M217.752 132.47C217.752 180.658 178.689 219.721 130.501 219.721V224.515C181.336 224.515 222.546 183.305 222.546 132.47H217.752ZM130.501 219.721C82.3132 219.721 43.2494 180.658 43.2494 132.47H38.4558C38.4558 183.305 79.6658 224.515 130.501 224.515V219.721ZM43.2494 132.47C43.2494 84.2824 82.3132 45.2186 130.501 45.2186V40.425C79.6658 40.425 38.4558 81.635 38.4558 132.47H43.2494ZM130.501 45.2186C178.689 45.2186 217.752 84.2824 217.752 132.47H222.546C222.546 81.635 181.336 40.425 130.501 40.425V45.2186Z'
        fill='url(#paint3_linear_115_2677)'
        mask='url(#path-3-inside-2_115_2677)'
      />
    </g>
    <g filter='url(#filter2_f_115_2677)'>
      <mask
        id='path-5-inside-3_115_2677'
        fill='white'
      >
        <path d='M220.149 132.47C220.149 181.981 180.012 222.118 130.501 222.118C80.9895 222.118 40.8526 181.981 40.8526 132.47C40.8526 82.9587 80.9895 42.8218 130.501 42.8218C180.012 42.8218 220.149 82.9587 220.149 132.47Z' />
      </mask>
      <path
        d='M217.752 132.47C217.752 180.658 178.689 219.721 130.501 219.721V224.515C181.336 224.515 222.546 183.305 222.546 132.47H217.752ZM130.501 219.721C82.3132 219.721 43.2494 180.658 43.2494 132.47H38.4558C38.4558 183.305 79.6658 224.515 130.501 224.515V219.721ZM43.2494 132.47C43.2494 84.2824 82.3132 45.2186 130.501 45.2186V40.425C79.6658 40.425 38.4558 81.635 38.4558 132.47H43.2494ZM130.501 45.2186C178.689 45.2186 217.752 84.2824 217.752 132.47H222.546C222.546 81.635 181.336 40.425 130.501 40.425V45.2186Z'
        fill='url(#paint4_linear_115_2677)'
        mask='url(#path-5-inside-3_115_2677)'
      />
    </g>
    <path
      d='M145.94 135.092C145.83 135.28 145.813 135.507 145.894 135.709L154.037 155.969L116.872 107.638L131.397 120.217C131.592 120.386 131.863 120.434 132.104 120.343L161.018 109.399L145.94 135.092ZM100.614 121.825L108.531 104.57L148.949 158.174L100.614 121.825ZM116.985 141.728L128.279 149.331L101.272 161.858L116.985 141.728Z'
      fill='url(#paint5_linear_115_2677)'
      stroke='url(#paint6_linear_115_2677)'
      strokeWidth='1.40189'
      strokeLinejoin='round'
    />
    <g style={{ mixBlendMode: 'plus-lighter' }}>
      <mask
        id='mask0_115_2677'
        style={{ maskType: 'alpha' }}
        maskUnits='userSpaceOnUse'
        x='24'
        y='24'
        width='212'
        height='212'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M129.7 235.399C188.076 235.399 235.399 188.076 235.399 129.7C235.399 71.3234 188.076 24 129.7 24C71.3234 24 24 71.3234 24 129.7C24 188.076 71.3234 235.399 129.7 235.399ZM130.022 205.568C170.671 205.568 203.623 172.616 203.623 131.968C203.623 91.3191 170.671 58.367 130.022 58.367C89.3738 58.367 56.4217 91.3191 56.4217 131.968C56.4217 172.616 89.3738 205.568 130.022 205.568Z'
          fill='#0C111A'
        />
      </mask>
      <g mask='url(#mask0_115_2677)'>
        <g style={{ mixBlendMode: 'lighten' }}>
          <rect
            x='-7.23041'
            y='-7.22266'
            width='273.858'
            height='273.858'
            fill='url(#pattern0_115_2677)'
            fillOpacity='0.5'
          />
        </g>
      </g>
    </g>
    <defs>
      <filter
        id='filter0_b_115_2677'
        x='11.9389'
        y='13.9081'
        width='237.124'
        height='237.124'
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'
      >
        <feFlood
          floodOpacity='0'
          result='BackgroundImageFix'
        />
        <feGaussianBlur
          in='BackgroundImageFix'
          stdDeviation='14.4568'
        />
        <feComposite
          in2='SourceAlpha'
          operator='in'
          result='effect1_backgroundBlur_115_2677'
        />
        <feBlend
          mode='normal'
          in='SourceGraphic'
          in2='effect1_backgroundBlur_115_2677'
          result='shape'
        />
      </filter>
      <filter
        id='filter1_b_115_2677'
        x='11.9389'
        y='13.9081'
        width='237.124'
        height='237.124'
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'
      >
        <feFlood
          floodOpacity='0'
          result='BackgroundImageFix'
        />
        <feGaussianBlur
          in='BackgroundImageFix'
          stdDeviation='14.4568'
        />
        <feComposite
          in2='SourceAlpha'
          operator='in'
          result='effect1_backgroundBlur_115_2677'
        />
        <feBlend
          mode='normal'
          in='SourceGraphic'
          in2='effect1_backgroundBlur_115_2677'
          result='shape'
        />
      </filter>
      <filter
        id='filter2_f_115_2677'
        x='28.8686'
        y='30.8377'
        width='203.265'
        height='203.264'
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'
      >
        <feFlood
          floodOpacity='0'
          result='BackgroundImageFix'
        />
        <feBlend
          mode='normal'
          in='SourceGraphic'
          in2='BackgroundImageFix'
          result='shape'
        />
        <feGaussianBlur
          stdDeviation='5.99202'
          result='effect1_foregroundBlur_115_2677'
        />
      </filter>
      <pattern
        id='pattern0_115_2677'
        patternContentUnits='objectBoundingBox'
        width='1'
        height='1'
      >
        <use
          xlinkHref='#image0_115_2677'
          transform='scale(0.002)'
        />
      </pattern>
      <radialGradient
        id='paint0_radial_115_2677'
        cx='0'
        cy='0'
        r='1'
        gradientUnits='userSpaceOnUse'
        gradientTransform='translate(137.882 139.195) rotate(77.7206) scale(101.896)'
      >
        <stop
          offset='0.421666'
          stopColor='#0B0B0E'
        />
        <stop
          offset='1'
          stopColor='#0C0B0F'
        />
      </radialGradient>
      <linearGradient
        id='paint1_linear_115_2677'
        x1='55.2934'
        y1='48.1449'
        x2='365.237'
        y2='273.36'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#65F6FF' />
        <stop
          offset='1'
          stopColor='#FF68F0'
          stopOpacity='0'
        />
      </linearGradient>
      <radialGradient
        id='paint2_radial_115_2677'
        cx='0'
        cy='0'
        r='1'
        gradientUnits='userSpaceOnUse'
        gradientTransform='translate(130.519 132.241) rotate(74.1772) scale(105.015)'
      >
        <stop
          stopColor='#09080B'
          stopOpacity='0'
        />
        <stop
          offset='0.486666'
          stopColor='#09080B'
          stopOpacity='0'
        />
        <stop
          offset='0.711666'
          stopColor='#081B1E'
          stopOpacity='0.126623'
        />
        <stop
          offset='1'
          stopColor='#9D1F90'
        />
      </radialGradient>
      <linearGradient
        id='paint3_linear_115_2677'
        x1='55.2934'
        y1='48.1449'
        x2='365.237'
        y2='273.36'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#65F6FF' />
        <stop
          offset='1'
          stopColor='#FF68F0'
          stopOpacity='0'
        />
      </linearGradient>
      <linearGradient
        id='paint4_linear_115_2677'
        x1='55.2934'
        y1='48.1449'
        x2='365.237'
        y2='273.36'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#65F6FF' />
        <stop
          offset='1'
          stopColor='#FF68F0'
          stopOpacity='0'
        />
      </linearGradient>
      <linearGradient
        id='paint5_linear_115_2677'
        x1='128.705'
        y1='101.565'
        x2='132.418'
        y2='162.939'
        gradientUnits='userSpaceOnUse'
      >
        <stop
          offset='0.100583'
          stopColor='#4D75F0'
        />
        <stop
          offset='0.354473'
          stopColor='#B5B9FB'
        />
        <stop
          offset='0.540959'
          stopColor='white'
        />
        <stop
          offset='0.576908'
          stopColor='#234FAF'
        />
        <stop
          offset='0.743712'
          stopColor='#3496E1'
        />
        <stop
          offset='1'
          stopColor='#64E1FD'
        />
      </linearGradient>
      <linearGradient
        id='paint6_linear_115_2677'
        x1='128.705'
        y1='101.565'
        x2='132.418'
        y2='162.939'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='white' />
        <stop
          offset='0.541667'
          stopColor='#507AE9'
        />
        <stop
          offset='0.567708'
          stopColor='white'
          stopOpacity='0.94047'
        />
        <stop
          offset='1'
          stopColor='#9AFFE8'
        />
      </linearGradient>
      <image
        id='image0_115_2677'
        width='500'
        height='500'
        href={XyroBubbleMask}
      />
    </defs>
  </svg>
)

export default XyroBubble

import { Flex } from '@radix-ui/themes'
import { XyroLogoText, XyroTokenMetallic } from 'shared/icons'
import { SocialTypes } from '../constants'
import { SocialItem } from './social-item'

const Header: React.FC = () => {
  return (
    <Flex
      align={'center'}
      justify={{ initial: 'start', lg: 'center' }}
      position={'relative'}
      pt={'6'}
    >
      <Flex
        align={'center'}
        gap={'1'}
      >
        <XyroTokenMetallic
          height={'2.5rem'}
          width={'2.5rem'}
        />

        <XyroLogoText
          width={'8.5rem'}
          height={'2.75rem'}
        />
      </Flex>

      <Flex
        align={'center'}
        gap={'2'}
        position={'absolute'}
        right={'0'}
      >
        <SocialItem
          type={SocialTypes.discord}
          position={'header'}
        />

        <SocialItem
          type={SocialTypes.twitter}
          position={'header'}
        />

        <SocialItem
          type={SocialTypes.telegram}
          position={'header'}
        />
      </Flex>
    </Flex>
  )
}

export default Header

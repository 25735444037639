import { useEffect } from 'react'
import { useQuery, useReactiveVar } from '@apollo/client'
import { REWARDS_GET_USER_LEVEL } from 'api/rewards/rewards-get-user-level'
import { currentUserLevelVar, userVar } from 'shared/store/user'

export const useGetUserLevel = () => {
  const user = useReactiveVar(userVar)

  const { data: userLevelQuery } = useQuery(REWARDS_GET_USER_LEVEL, {
    variables: {
      userId: user?.id || ''
    },
    skip: !user?.id
  })

  useEffect(() => {
    const userLevelData = userLevelQuery?.getUserLevel

    if (!userLevelData) return

    const currentUserLevel = userLevelData?.levelId || 0

    currentUserLevelVar(currentUserLevel)
  }, [userLevelQuery])
}

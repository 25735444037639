import { Flex, Link, Text } from '@radix-ui/themes'
import styles from '../about.module.scss'

interface Props {
  title: string
  href: string
}
export const GradientLink: React.FC<Props> = ({ title, href }) => (
  <Link
    href={href}
    className={styles.launchLinkWrapper}
  >
    <Flex className={styles.launchButtonWrapperOutline}>
      <Flex className={styles.launchButtonWrapper}>
        <Flex
          className={styles.launchLink}
          justify={'center'}
        >
          <Text
            className={'color-white'}
            size={'2'}
            weight={'bold'}
          >
            {title}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  </Link>
)

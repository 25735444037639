import {
  Alchemy,
  AnimocaBrands,
  AnimocaBrandsLogo,
  Arbitrum,
  AwenVentures,
  Blacknode,
  Blockx,
  ChainGPT,
  CarbonVentures,
  CastrumCapital,
  Chainlink,
  Contango,
  Cookie3,
  Covey,
  Eesee,
  FdCapital,
  JetsCapital,
  LiquidX,
  LondonVentures,
  MavenCapital,
  Memefi,
  MerkleCapital,
  MonProtocol,
  NewtribeCapital,
  Nxgen,
  OddiyanaVentures,
  Wagmi,
  Zealy,
  Zokyo
} from 'shared/icons/about-page'
import { InvestorsProps } from './types'

export const BACKERS_LIST: InvestorsProps[] = [
  {
    icon: AnimocaBrands,
    borderColor: '#31A4DE',
    gridSettings: {
      mobile: {
        rowSpan: 2,
        colSpan: 2
      },
      desktop: {
        rowSpan: 2,
        colSpan: 2
      }
    }
  },
  { icon: MavenCapital, gridSettings: { mobile: { rowSpan: 2 } } },
  { icon: OddiyanaVentures },
  { icon: Nxgen },
  { icon: MerkleCapital },
  { icon: Blockx },
  { icon: LiquidX },
  { icon: NewtribeCapital },
  { icon: Covey },
  { icon: CarbonVentures },
  { icon: Blacknode },
  { icon: Contango },
  { icon: AwenVentures },
  { icon: CastrumCapital },
  { icon: JetsCapital },
  { icon: LondonVentures },
  { icon: FdCapital }
]

export const PARTNERS_LIST_DESKTOP: InvestorsProps[] = [
  {
    icon: MonProtocol,
    gridSettings: { desktop: { rowSpan: 3 }, mobile: { rowSpan: 3 } },
    borderColor: 'rgba(240, 236, 233, 0.80)'
  },
  { icon: Cookie3 },
  {
    icon: Chainlink,
    gridSettings: {
      desktop: { colSpan: 2, rowSpan: 2 },
      mobile: { colSpan: 2, rowSpan: 2 }
    },
    borderColor: '#0847F7'
  },
  { icon: Memefi },
  { icon: Wagmi },
  { icon: Eesee },
  { icon: Alchemy },
  { icon: Zealy },
  {
    icon: Arbitrum,
    gridSettings: {
      desktop: { colSpan: 2, rowSpan: 2 },
      mobile: { colSpan: 2 }
    },
    borderColor: 'rgba(157, 204, 237, 0.50)'
  },
  { icon: ChainGPT },
  { icon: Zokyo },
  { icon: AnimocaBrandsLogo }
]

export const PARTNERS_LIST_MOBILE: InvestorsProps[] = [
  {
    icon: MonProtocol,
    gridSettings: { desktop: { rowSpan: 3 }, mobile: { rowSpan: 3 } },
    borderColor: 'rgba(240, 236, 233, 0.80)'
  },
  {
    icon: Chainlink,
    gridSettings: {
      desktop: { colSpan: 2, rowSpan: 2 },
      mobile: { colSpan: 2, rowSpan: 2 }
    },
    borderColor: '#0847F7'
  },
  { icon: Alchemy },
  { icon: Zealy },
  { icon: ChainGPT },
  { icon: Zokyo },
  { icon: AnimocaBrandsLogo },
  { icon: Cookie3 },
  { icon: Memefi },
  { icon: Eesee },
  { icon: Wagmi },
  {
    icon: Arbitrum,
    gridSettings: {
      desktop: { colSpan: 2, rowSpan: 2 },
      mobile: { colSpan: 2 }
    },
    borderColor: 'rgba(157, 204, 237, 0.50)'
  }
]
